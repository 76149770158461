<template>
    <div class="modal-w position-fixed start-0 top-0 d-grid" v-if="clauses.show">
        <div class="modal-p position-relative p-4 pt-5" v-if="clauses.show">
            <div @click="clausesClose" role="button" class="btn-close"></div>

            <form>
                <row-component>
                    <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                        <label>Importante:</label>
                        <checkbox-component v-if="!isEdit" class="my-2" @checkboxSwitcher="importantChange"/>
                        <checkbox-component v-else class="my-2" @checkboxSwitcher="importantChange"
                                            :input-checked="parseInt(clauses.formData.important) === 2"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <QuillEditor :contentType="'html'" :toolbar="toolbar" theme="snow"
                                     v-model:content="clauses.formData.item"/>
                    </row-col-component>
                </row-component>

                <row-component class="mt-5">
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <button-one v-if="!isEdit" @click="submitClause" icon="plus" name-button="Adicionar"/>
                        <button-one v-else @click="updateClause" icon="save" name-button="Salvar"/>
                    </row-col-component>
                </row-component>
            </form>
        </div>
    </div>

    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tabs effect-3" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="tasks icon"></i>
                <p>Sessão</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <row-component>
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 clearfix'">
                            <button-add class="m-0" :itens="itensThree" v-if="buttonCheck.create"/>
                        </row-col-component>
                    </row-component>

                    <form>
                        <row-component>
                            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.name }">
                                <label>Nome:</label>
                                <input-component :maxlength=100 :type-input="'text'" v-model="formDate.name"/>
                            </row-col-component>

                        </row-component>

                      <row-component>
                        <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.status }">
                          <label>Status:</label>
                          <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                       :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                        </row-col-component>


                        <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.type }">
                          <label>Tipo de contrato:</label>
                          <multiselect v-model="formDate.type" :searchable="true" :options="selectTypeContract"
                                       :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.price }">
                          <label>Valor Total:</label>
                          <input-component :type-input="'text'" v-model="formDate.price" v-money="money"/>
                        </row-col-component>

                      </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Observações:</label>
                                <input-text-area :rows="4" v-model="formDate.obs"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <button-one class="w-100" @submit="submit" :icon="'save'" :name-button="'Salvar'"
                                            :class="{ 'preloderSend': sendForm }"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <button-float class="w-100" :itens="itensTwo"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <button-one @click="clauses.show = true" icon="plus"/>

                    <div class="alert alert-secondary alert-dismissible fade show mt-3" role="alert"
                         v-if="formDate.itens.length === 0">
                        <p>Não há cláusulas cadastradas</p>
                    </div>

                    <draggable v-else v-model="formDate.itens" @start="drag=true" @end="onEnd" item-key="id">
                        <template #item="{element, index}">
                            <div class="accordion mt-3" :id="'accordionExample' + index">
                                <div class="accordion-item position-relative">
                                    <h2 class="accordion-header">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                :data-bs-target="'#collapseOne' + index" aria-expanded="true"
                                                aria-controls="collapseOne">
                                            Cláusula {{ index + 1 }}
                                        </button>

                                        <div class="btn-group" role="group">
                                            <button class="btn btn-main" @click="clausesEdit(element)">
                                                <i class="edit icon"></i>
                                            </button>

                                            <button class="btn btn-main" @click="deleteClause(element)">
                                                <i class="trash icon"></i>
                                            </button>
                                        </div>
                                    </h2>

                                    <div :id="'collapseOne' + index" class="accordion-collapse collapse show"
                                         :data-bs-parent="'#accordionExample' + index">
                                        <div class="accordion-body">
                                            <row-component>
                                                <row-col-component
                                                        :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                                    <label>Importante:</label>
                                                    <checkbox-component class="my-2"
                                                                        :input-checked="parseInt(element.important) === 2"
                                                                        @checkboxSwitcher="importantChange(index)"/>
                                                </row-col-component>
                                            </row-component>

                                            <QuillEditor :contentType="'html'" read-only theme="bubble"
                                                         v-model:content="element.item"
                                                         style="border:1px solid #d1d5db; padding: 1rem;"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </draggable>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import ButtonOne from "@/components/buttons/ButtonOne";
import ButtonAdd from "@/components/buttons/ButtonAdd"
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {ACTION_MESSAGES} from "@/configs/constants";
import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import {QuillEditor, Quill} from "@vueup/vue-quill";
import CheckboxComponent from "@/components/form/CheckboxComponent";
import swal from "sweetalert";
import draggable from "vuedraggable";
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const toast = useToast();

let Font = Quill.import('formats/font');
Font.whitelist = ['serif', 'monospace', 'Calibri'];
Quill.register(Font, true);

export default {
    name: "ContractModelUpdate",
    components: {
        CheckboxComponent,
        QuillEditor,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        ButtonOne,
        ButtonAdd,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea,
        draggable
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Modelo de Contrato')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Editar",
                icon: "edit"
            },

            itensTwo: {
                route: "/contract-model/search",
                icon: "reply all",
                name: 'Voltar'
            },

            itensThree: {
                route: "/contract-model/create",
                icon: "plus",
                name: 'Adicionar'
            },

            buttonCheck: {
                create: false,
            },

            formDate: {
                name: '',
                price: '',
                obs: '',
                status: '',
                type:'',
                itens: []
            },

            drag: false,

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,

            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'direction': 'rtl'}],
                [{'size': ['small', false, 'large', 'huge']}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],
                // [{'font': ['Calibri']}],
                [{'align': []}],
                ['clean']
            ],

            isEdit: false,
            important: 1,
            clauses: {
                show: false,
                formData: {
                    contract_model_id: this.id,
                    item: "",
                    important: "",
                    order: ""
                }
            }
        }
    },

    methods: {
        scrollTop() {
            window.scrollTo(0, 0)
        },

        loadPageItens() {
            this.$store.dispatch('loadContractModel', this.id)
                .then((r) => {
                    this.formDate = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateContractModel', this.formDate)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    this.scrollTop()
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 23)
                .then((r) => {
                    if (r.data.indexOf('contract-model-edit') !== -1) {
                        this.showLoader = false
                    }
                    if (r.data.indexOf('contract-model-create') !== -1) {
                        this.buttonCheck.create = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        importantChange() {
            if (parseInt(this.important) === 1) {
                this.important = 2
            } else {
                this.important = 1
            }
        },

        clausesClose() {
            this.isEdit = false
            this.clauses.show = false
            this.clauses.formData.item = ""
            this.clauses.formData.important = 1
            this.important = 1
        },

        clausesEdit(data) {
            this.isEdit = true
            this.clauses.show = true
            this.important = data.important
            this.clauses.formData = {
                id: data.id,
                contract_model_id: this.id,
                item: data.item,
                important: data.important,
            }
        },

        submitClause() {
            this.clauses.formData.important = this.important
            this.clauses.formData.order = this.formDate.itens.length + 1
            this.$store.dispatch('storeContractModelItem', this.clauses.formData)
                .then(() => {
                    toast.success(ACTION_MESSAGES.create, {timeout: 2000});
                    this.clausesClose()
                    this.loadPageItens()
                }).catch(() => {
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        updateClause() {
            this.clauses.formData.important = this.important
            this.$store.dispatch('updateContractModelItem', this.clauses.formData)
                .then(() => {
                    toast.success(ACTION_MESSAGES.update, {timeout: 2000});
                    this.clausesClose()
                    this.loadPageItens()
                }).catch(() => {
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        onEnd() {
            this.drag = false
            let clauses = this.formDate.itens
            let formData = {id: this.id, itens: []}

            clauses.forEach((element, index) => {
                formData.itens.push({
                    id: element.id,
                    order: index
                })
            })
            this.$store.dispatch("updateOrderContractModelItem", formData)
        },

        deleteClause(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar esta cláusula ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyContractModelItem', item.id).then(() => {
                        this.loadPageItens(1)
                        toast.success(ACTION_MESSAGES.delete, {timeout: 2000});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar`, {timeout: 2000});
                    })
                }
            });
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

        storeState() {
            return this.$store.state.var.storeState
        },

        sectionAdd() {
            return this.formDateSection.sections;
        },

        itemAdd() {
            return this.formDateItem.itens;
        },

      selectTypeContract() {
        return this.$store.state.var.selectTypeContract
      },
    },
}
</script>

<style>
.ql-container {
    font-size: 16px;
    font-family: 'Calibri';
}
</style>

<style scoped>
@font-face {
    font-family: "Calibri";
    src: url("@/assets/fonts/calibri-regular.ttf");
}

.ql-font-Calibri {
    font-family: Calibri;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.btn-group {
    top: 0;
    right: 40px;
    margin: 6px;
    z-index: 20;
    position: absolute;
}

.btn-main {
    color: #d67c1c;
    --bs-btn-border-width: 0;
}

.btn-main:active,
.btn-main:focus,
.btn-main:hover,
.btn-main:focus-visible {
    border: 0;
    color: #d67c1c;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: #000000;
    background-color: #f0f0f0;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:focus {
    box-shadow: none;
}

.modal-w {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-p {
    width: 65%;
    height: auto;
    background: #FFFFFF;
}

.modal-p .btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}
</style>

