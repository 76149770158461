<template>
    <painel-component :itens="itensOne">
        <error-component :errors="errors" v-if="showError"/>
        <loder-send-component :text-title="'Carregando'" v-if="showLoader"/>

        <div class="tabs effect-3" v-else>
            <input type="radio" id="tab-1" name="tab-effect-3" checked="checked">
            <span>
                <i class="info circle icon"></i>
                <p>Informações</p>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="tasks icon"></i>
                <p>Sessão</p>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'"
                                               :class="{ 'formError': errors.name }">
                                <label>Nome Contato:</label>
                                <input-component :type-input="'text'" v-model="formDate.name" disabled/>
                            </row-col-component>

                        </row-component>

                      <row-component>
                        <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.status }">
                          <label>Status:</label>
                          <multiselect v-model="formDate.status" :searchable="true" :options="selectInput"
                                       :noResultsText="noResultsText" :noOptionsText="noResultsText" disabled/>
                        </row-col-component>


                        <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.type }">
                          <label>Tipo de contrato:</label>
                          <multiselect v-model="formDate.type" :searchable="true" :options="selectTypeContract"
                                       :noResultsText="noResultsText" :noOptionsText="noResultsText" disabled/>
                        </row-col-component>

                        <row-col-component :class-row-col="'col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12'"
                                           :class="{ 'formError': errors.price }">
                          <label>Valor Total:</label>
                          <input-component :type-input="'text'" v-model="formDate.price" v-money="money" disabled/>
                        </row-col-component>

                      </row-component>



                      <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                <label>Observações:</label>
                                <input-text-area :rows="4" v-model="formDate.obs" disabled/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formDate.itens.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                  <draggable v-else v-model="formDate.itens" @start="drag=true" @end="onEnd" item-key="id" disabled="disabled">
                    <template #item="{element, index}">
                      <div class="accordion mt-3" :id="'accordionExample' + index">
                        <div class="accordion-item position-relative">
                          <h2 class="accordion-header">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    :data-bs-target="'#collapseOne' + index" aria-expanded="true"
                                    aria-controls="collapseOne">
                              Cláusula {{ index + 1 }}
                            </button>


                          </h2>

                          <div :id="'collapseOne' + index" class="accordion-collapse collapse show"
                               :data-bs-parent="'#accordionExample' + index">
                            <div class="accordion-body">
                              <row-component>
                                <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                  <label>Importante:</label>
                                  <checkbox-component class="my-2"
                                                      :input-checked="parseInt(element.important) === 2"
                                                      @checkboxSwitcher="importantChange(index)" disabled/>
                                </row-col-component>
                              </row-component>

                              <QuillEditor :contentType="'html'" read-only theme="bubble"
                                           v-model:content="element.item"
                                           style="border:1px solid #d1d5db; padding: 1rem;"/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </draggable>




                </section>
            </div>
        </div>

        <row-component>
            <row-col-component
                    :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <button-float class="w-100" :itens="itensTwo"/>
            </row-col-component>
        </row-component>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonFloat from "@/components/buttons/ButtonFloat";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent"
import LoderSendComponent from "@/components/LoderSendComponent";
import InputTextArea from "@/components/form/InputTextArea";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import {QuillEditor, Quill} from "@vueup/vue-quill";


import {MONEY} from "@/configs/constants"
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import {useToast} from "vue-toastification";
import draggable from "vuedraggable";
import CheckboxComponent from "@/components/form/CheckboxComponent.vue";
let Font = Quill.import('formats/font');
Font.whitelist = ['serif', 'monospace', 'Calibri'];
const toast = useToast();

export default {
    name: "ContractModelShow",
    components: {
      CheckboxComponent, draggable,
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonFloat,
        Multiselect,
        ErrorComponent,
        LoderSendComponent,
        InputTextArea,
      QuillEditor
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch('updateNamePage', 'Modelo de Contrato')
        this.loadPageItens()
        this.checkACL()
    },

    data() {
        return {
            itensOne: {
                title: "Ver",
                icon: "eye"
            },

            itensTwo: {
                route: "/contract-model/search",
                icon: "reply all",
                name: 'Voltar'
            },

            formDate: {
                name: '',
                price: '',
                obs: '',
                status: '',
                itens: '',
                type:''
            },

            formDateSection: {
                id: this.id,
                sections: [],
            },

            formDateSectionEdit: {
                id: '',
                item: ''
            },

            formDateItemEdit: {
                id: '',
                item: ''
            },

            formDateItem: {
                id: '',
                itens: []
            },

            money: MONEY,

            errors: [],
            showError: false,
            sendForm: false,
            showLoader: true,
        }
    },

    methods: {
        scrollTop() {
            window.scrollTo(0, 0)
        },

        loadPageItens() {
            this.$store.dispatch('loadContractModel', this.id).then((r) => {
                this.formDate = r;
            }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLitens', 23)
                .then((r) => {
                    if (r.data.indexOf('contract-model-search') !== -1) {
                        this.showLoader = false
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.selectNotSearch
        },

        selectInput() {
            return this.$store.state.var.selectActiveInactive
        },

      selectTypeContract() {
        return this.$store.state.var.selectTypeContract
      },


    },
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.ql-container {
  font-size: 16px;
  font-family: 'Calibri';
}
</style>

<style scoped>
@font-face {
  font-family: "Calibri";
  src: url("@/assets/fonts/calibri-regular.ttf");
}

.ql-font-Calibri {
  font-family: Calibri;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
  width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
  left: 50%;
}

.btn-group {
  top: 0;
  right: 40px;
  margin: 6px;
  z-index: 20;
  position: absolute;
}

.btn-main {
  color: #d67c1c;
  --bs-btn-border-width: 0;
}

.btn-main:active,
.btn-main:focus,
.btn-main:hover,
.btn-main:focus-visible {
  border: 0;
  color: #d67c1c;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #000000;
  background-color: #f0f0f0;
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:focus {
  box-shadow: none;
}

.modal-w {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-p {
  width: 65%;
  height: auto;
  background: #FFFFFF;
}

.modal-p .btn-close {
  top: 10px;
  right: 10px;
  position: absolute;
}


</style>